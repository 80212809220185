import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../../components/Layout"

export default function PageTemplate({ pageContext: { language, availableLanguages }, data: { mdx } }) {

	const title = mdx.frontmatter.header || mdx.frontmatter.title

	return (
		<Layout
			lang={language}
			availableLanguages={availableLanguages}
			mdx={mdx}
			title={title}
			showFullWidthCover={true}
			customTitle={true}
			indexThis={!mdx.frontmatter.skipIndex}
			{...mdx.frontmatter}
		>
			<div id="mdx-body">
				<MDXRenderer>{mdx.body}</MDXRenderer>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query PageQuery($id: String) {
		mdx(id: { eq: $id }) {
			id
			body
			fields {
				originalPath
				draft
			}
			frontmatter {
				title
				header
				subtitle
				pathname
				subto
				cover {
					publicURL
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
						thumbnail: gatsbyImageData(layout: CONSTRAINED, width: 160, height: 160)
					}
				}
				hideCover
				skipIndex
			}
		}
	}
`